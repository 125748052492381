import React from "react";
import { IonAvatar, IonBadge, IonButton, IonCard, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonPopover } from "@ionic/react";
import './info.css';
import { codeSlashOutline, downloadOutline, listOutline, menuOutline, playCircleOutline } from "ionicons/icons";
import LandingHeaderComponent from "../../components/landing-header-component";
import LandingFooterComponent from "../../components/landing-footer-component";

const TechnologyPage: React.FC = () => {

    return (

        <IonPage>
            <IonContent id="menuContent" >
                <LandingHeaderComponent/>                
                <IonGrid className="ion-no-padding">
                    <IonRow className="info-half-row  background-dark ion-padding">
                        <IonCol size-md="4" offset-md="2.5"   size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                    Technical Details
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </IonText>
                                </p>


                            </div>
                        </IonCol>
                        <IonCol size="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="2.5">

                            <IonItem lines="full" className="info-item ion-no-padding">
                                <IonAvatar slot="start" className="ion-margin-top ion-margin-start">
                                    <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                </IonAvatar>
                                <IonText className="info-card-title ion-no-margin ">
                                    Introducing XAR
                                </IonText>
                            </IonItem>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.
                                </IonText>
                            </p>

                        </IonCol>
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="0.5">

                            <IonItem lines="full" className="info-item ion-no-padding">
                                <IonAvatar slot="start" className="ion-margin-top ion-margin-start">
                                    <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                </IonAvatar>
                                <IonText className="info-card-title ion-no-margin ">
                                    ProximaX Sirius
                                </IonText>
                            </IonItem>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                    The Xarcade platform distributes video games that are developed based on ProximaX Sirius platform technology.
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="2.5">

                            <IonItem lines="full" className="info-item ion-no-padding">
                                <IonAvatar slot="start" className="ion-margin-top ion-margin-start">
                                    <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                </IonAvatar>
                                <IonText className="info-card-title ion-no-margin ">
                                    Another Tech Item
                                </IonText>
                            </IonItem>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                    The Xarcade platform distributes video games that are developed based on ProximaX Sirius platform technology.
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="0.5">

                        <IonItem lines="full" className="info-item ion-no-padding">
                            <IonAvatar slot="start" className="ion-margin-top ion-margin-start">
                                <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <IonText className="info-card-title ion-no-margin ">
                            Another Tech Item
                            </IonText>
                        </IonItem>
                        <p  className=" ion-margin-start">
                            <IonText className="landing-image-box-text ion-justify-content-center">
                                The Xarcade platform distributes video games that are developed based on ProximaX Sirius platform technology.
                            </IonText>
                        </p>
                        </IonCol>
                        <IonCol size-md="3" offset-md="2.5">
                            <IonButton className="info-create-account-button" color="primary" href="https://app.xarcade.io/api-dashboard/register.html">
                                <IonIcon icon={downloadOutline} color="light" className="ion-padding-end" ></IonIcon>
                                <p className="ion-text-left">
                                    <IonText className="info-button-title">Project Paper</IonText><br/>
                                    <IonText className="info-button-subtitle">Download our project paper</IonText>
                                </p>
                            </IonButton>
                        </IonCol>

                    </IonRow>
                    <IonRow className="info-half-row background-dark-tint ion-padding">
                        <IonCol size-md="6" offset-md="3">
                            <div className="ion-text-center ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Platform Algorithm
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                    Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                                    </IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark-tint ion-padding">
                        <IonCol size-md="10" offset-md="1" className="info-card-blank ion-text-center">
                            <IonText color="light">Infographic here</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol size-md="6" offset-md="3">
                            <div className="ion-text-center ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        System Architecture
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                    </IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol size-md="10" offset-md="1" className="info-card-blank ion-text-center">
                            <IonText color="light">Infographic here</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark-tint ion-padding">
                        <IonCol size-md="6" offset-md="3">
                            <div className="ion-text-center ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Roadmap
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                    </IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark-tint ion-padding">
                        <IonCol size-md="10" offset-md="1" className="info-card-blank ion-text-center">
                            <IonText color="light">Infographic here</IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="1">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                                <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Introducing XAR
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Duis aute irure dolor in reprehenderit in voluptate velit esse.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol   className="ion-margin ion-padding  info-card" size-md="3">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Xarcade Technology
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Ut enim ad minima veniam, quis nostrum exercitationem ullam.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol  className="ion-margin ion-padding  info-card" size-md="3">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Gamer's Perks
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Ut enim ad minima veniam, quis nostrum exercitationem ullam.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                    </IonRow>


                </IonGrid>
                <LandingFooterComponent/>
            </IonContent>
        </IonPage>
    );
};

export default TechnologyPage;
