import { IonButton, IonHeader, IonIcon, IonImg, IonItem, IonItemGroup, IonAvatar, IonList, IonLabel, IonMenuButton, IonToolbar, useIonPopover, IonText, IonChip, IonModal, IonTabs, IonTabBar, IonTabButton, IonBadge, IonSegment, IonSegmentButton } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  menuOutline, chevronDownOutline, exitOutline, helpCircleOutline, notificationsCircleOutline, notificationsOutline, calendar, personCircle, map, informationCircle } from 'ionicons/icons';
import { useState } from 'react';
import './header-footer-component.css';
import LandingTabsComponent from './landing-tabs';




const HeaderComponent: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
        {
            (window.innerWidth > 600) ?
            <IonHeader>
                <IonToolbar className="ion-padding-horizontal ion-text-center header-toolbar">

                    <IonImg slot="start" className="header-logo ion-margin" src="assets/icon/xarcade-logo-games.svg"/>
                    <IonText color="light" className=""slot="start">Good Morning, Waston!</IonText>
                    <IonButton slot ="end" color="light" fill="clear"> <IonIcon color="light" icon={helpCircleOutline}></IonIcon></IonButton>
                    <IonButton slot ="end" color="light" fill="clear"> <IonIcon color="light" icon={notificationsOutline}></IonIcon></IonButton>
                    <IonItem slot="end" onClick={() => setShowModal(true)}>
                        <IonAvatar >
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                        </IonAvatar>
                    </IonItem>


                    <IonModal isOpen={showModal} cssClass='wallet-modal'>
                        <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)}>
                            <IonSegmentButton value="friends" >
                                <IonLabel color="light">My Profile</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="enemies">
                                <IonLabel color="light">My Wallet</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>

                            {/* <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton> */}
                    </IonModal>
                </IonToolbar>
            </IonHeader>

            :
            <IonToolbar className=" landing-toolbar">
                <LandingTabsComponent/>

                <IonMenuButton  color="light" slot="end">
                    <IonIcon icon={menuOutline}  slot="start" color="light"/> 
                </IonMenuButton>
                <IonImg slot="start" className="landing-logo ion-margin-end " src="assets/icon/Xarcade-RGB.png"/>
            </IonToolbar>



        }
        </>
    );
};

export default HeaderComponent;
