import { IonBadge, IonButton, IonCard, IonCardSubtitle, IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonRouterOutlet, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendar, callOutline, caretForwardCircleOutline, codeWorkingOutline, diamondOutline, gameControllerOutline, gitCompareOutline, globeOutline, homeOutline, informationCircle, map, personCircle, personOutline, reorderThreeOutline } from 'ionicons/icons';
import './home-tabs.css';

const LandingTabsComponent: React.FC = () => {


    return (


        <IonMenu side="start" menuId="first" content-id="menuContent" id="split-pane">
            <IonHeader className="">
                <IonToolbar className="side-menu ion-padding-top" >
                    <IonImg className="logo  ion-padding" src="/assets/icon/xarcade-logo-white.png" />

                </IonToolbar>
                </IonHeader>
                <IonContent>
                <IonList>
                    <IonItem button className="home-tab-item" type="button" lines="none"><IonIcon icon={gameControllerOutline} slot="start" color="white"></IonIcon> Games</IonItem>
                    <IonItem button className="home-tab-item" lines="none"><IonIcon icon={codeWorkingOutline}  slot="start" color="white"/> Developers</IonItem>
                    <IonItem button className="home-tab-item" lines="none"><IonIcon icon={caretForwardCircleOutline}  slot="start" color="white"/> Getting Started</IonItem>
                    <IonItem button className="home-tab-item" lines="none"><IonIcon icon={callOutline}  slot="start" color="white"/> Support</IonItem>
                    <IonItem button className="home-tab-item" lines="none"><IonIcon icon={reorderThreeOutline}  slot="start" color="white"/> More</IonItem>
                </IonList>
                </IonContent>
                <IonFooter>
                <IonToolbar className="ion-margin-bottom ion-text-center">
                    <IonButton routerLink="/login" shape="round" expand="block" className="ion-padding-horizontal" fill="outline" color="light">Sign up</IonButton>

                </IonToolbar>
            </IonFooter>
        </IonMenu>

    );
};

export default LandingTabsComponent;
