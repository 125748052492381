import React from "react";
import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonRouterOutlet, IonRow, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonThumbnail, IonTitle, IonToolbar } from "@ionic/react";
import { calendar, informationCircle, map, menuOutline, personCircle } from "ionicons/icons";
import HomeTabsComponent from "../components/home-tabs";
import './home.css';
import GameCardComponent from "../components/game-card-component";

const HomePage: React.FC = () => {
    return (


            <IonContent id="menuContent">
                <IonSplitPane contentId="main" when="md"  > 
                <HomeTabsComponent active ="home" />
                <IonPage id="main" >
                    <IonContent id="home-content">    
                        <IonHeader className="ion-no-border " >
                            <IonToolbar className="home-toolbar">
                                {
                                    (window.innerWidth > 600) ?
                                    null
                                    :
                                    <IonMenuButton  color="light" slot="start">
                                        <IonIcon icon={menuOutline}  slot="start" color="light"/> 
                                    </IonMenuButton>
                                    
                                }
                                <IonButton  color="light" fill="clear">
                                    Browse Games
                                </IonButton>
                            </IonToolbar>
                            
                        </IonHeader>
                        
                        <IonGrid  className="white-text">
                            <IonRow  className="ion-padding-start">
                                {
                                    (window.innerWidth > 600) ?
                                    <IonCol size="8" className="ion-padding" >
                                        <IonText  className="large-text">
                                            Discover a different gaming experience.
                                        </IonText>
                                    </IonCol>
                                    :
                                    <IonCol size="12" className="ion-padding" >
                                    <IonText  className="large-text">
                                        Discover a different gaming experience.
                                    </IonText>
                                </IonCol>
                                }

                            </IonRow>
                            <IonRow className="ion-padding-start">
                                <IonText  className="ion-padding-start">
                                    Taking you to the next level of gaming technology.
                                </IonText>
                            </IonRow>
                            <IonRow className="huge-margin">
                                <IonCol>
                                    <IonCard className="ion-padding ion-margin-start main-card">
                                        <IonCardHeader>
                                            <IonCardTitle className="white-text">Featured Games</IonCardTitle>
                                        </IonCardHeader>

                                        <IonCardContent className="main-card-content">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size-sm="12" size-xs="12" size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Space Bits" description="Eliminate your enemies while you watch timers" img="/assets/games/spacebits.jpg"/>
                                                    </IonCol>
                                                    <IonCol size-sm="12" size-xs="12"  size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Chase The Brains" description="You are a zombie. Be scary, kill many." img="/assets/games/chasethebrains.jpg"/>
                                                    </IonCol>
                                                    <IonCol size-sm="12" size-xs="12"  size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Match' nem" description="Fast-paced match 3 game! 100 challenging levels!" img="/assets/games/matchnem.jpg"/>
                                                    </IonCol >
                                                    <IonCol size-sm="12" size-xs="12"  size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Lithomancer" description="A random gen maze runner as an arcade style game" img="/assets/games/lithomancer.jpg"/>
                                                    </IonCol>
                                                    <IonCol size-sm="12" size-xs="12"  size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Outrush" description="A new synthwave-inspired action-arcade game" img="/assets/games/outrush.jpg"/>
                                                    </IonCol>
                                                    <IonCol size-sm="12" size-xs="12"  size-xl="3" size-lg="6" size-md="12" >
                                                        <GameCardComponent gameName="Act Dead" description="Avoid bears, act dead" img="/assets/games/actdead.jpg"/>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>


                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                            </IonRow>

                        </IonGrid>
                        <IonFooter className="ion-no-border ion-padding-start">
                            <IonToolbar >
                                <IonButton shape="round" size = "small" fill="clear" color="light">About</IonButton>
                                <IonButton shape="round" size = "small" fill="clear" color="light">Contact</IonButton>
                                <IonButton shape="round" size = "small" fill="clear" color="light">Help</IonButton>
                                <IonButton shape="round" size = "small" fill="clear" color="light">Careers</IonButton>

                                <IonImg slot ="end" className="proximax" src="/assets/icon/ProximaX-logo.png" />
                            </IonToolbar>
                            <IonToolbar className="ion-text-center">
                                    <IonButton size="small" fill="clear" color="light" routerLink="/landing">
                                        <IonThumbnail slot="start" className="credits">
                                            <img src="/assets/icon/logo.png" />
                                        </IonThumbnail>
                                        <IonLabel slot="end" className="copyright">© Copyright 2021. Xarcade. All rights reserved.</IonLabel> 
                                    </IonButton>
                            </IonToolbar>
                        </IonFooter>
                    </IonContent>

                </IonPage>
                </IonSplitPane>
            </IonContent>
    );
};

export default HomePage;
