import React from "react";
import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonRouterOutlet, IonRow, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonThumbnail, IonTitle, IonToolbar } from "@ionic/react";
import { calendar, informationCircle, map, menuOutline, personCircle } from "ionicons/icons";
import HomeTabsComponent from "../../components/home-tabs";
import GameCardComponent from "../../components/game-card-component";
const InventoryPage: React.FC = () => {
    return (
        <IonContent id="menuContent">
        <IonSplitPane contentId="main" when="md"  > 
        <HomeTabsComponent active ="inventory" />
        <IonPage id="main" >
            <IonContent id="home-content">    
                <IonHeader className="ion-no-border " >
                    <IonToolbar className="home-toolbar">
                        {
                            (window.innerWidth > 600) ?
                            null
                            :
                            <IonMenuButton  color="light" slot="start">
                                <IonIcon icon={menuOutline}  slot="start" color="light"/> 
                            </IonMenuButton>
                            
                        }
                        <IonButton  color="light" fill="clear">
                            Browse Games
                        </IonButton>
                    </IonToolbar>
                    
                </IonHeader>

                <IonFooter className="ion-no-border ion-padding-start">
                    <IonToolbar >
                        <IonButton shape="round" size = "small" fill="clear" color="light">About</IonButton>
                        <IonButton shape="round" size = "small" fill="clear" color="light">Contact</IonButton>
                        <IonButton shape="round" size = "small" fill="clear" color="light">Help</IonButton>
                        <IonButton shape="round" size = "small" fill="clear" color="light">Careers</IonButton>

                        <IonImg slot ="end" className="proximax" src="/assets/icon/ProximaX-logo.png" />
                    </IonToolbar>
                    <IonToolbar className="ion-text-center">
                            <IonButton size="small" fill="clear" color="light" routerLink="/landing">
                                <IonThumbnail slot="start" className="credits">
                                    <img src="/assets/icon/logo.png" />
                                </IonThumbnail>
                                <IonLabel slot="end" className="copyright">© Copyright 2021. Xarcade. All rights reserved.</IonLabel> 
                            </IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonContent>

        </IonPage>
        </IonSplitPane>
    </IonContent>
    );
};

export default InventoryPage;
