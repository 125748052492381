import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import { IonReactHashRouter, IonReactRouter } from '@ionic/react-router';
import {
  IonApp,
  IonRouterOutlet,
} from "@ionic/react";

import RegisterPage from "./pages/auth/register";
import LoginPage from "./pages/auth/login";
import VerifyPage from "./pages/auth/verify";
import LandingPage from "./pages/landing";
import HomePage from "./pages/home";
import GameplayPage from "./pages/game/gameplay";

import ProfilePage from "./pages/user/profile";
import InventoryPage from "./pages/user/inventory";
import TransactionsPage from "./pages/user/transactions";

import GameDistributionPage from "./pages/info/game-distribution";
import AboutPage from "./pages/info/about";
import ReleaseNotesPage from "./pages/info/release-notes";
import TechnologyPage from "./pages/info/technology";

import HomeTabsComponent from './components/home-tabs';
import LandingTabsComponent from './components/landing-tabs';
import GameCardComponent from './components/game-card-component';
import LandingHeaderComponent from './components/landing-header-component';

import HeaderComponent from "./components/header-component";
import FooterComponent from "./components/footer-component";

import GamePage from "./pages/game/game-page";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";


const App: React.FC = () => {

  return(
    <IonApp>
      <IonReactHashRouter>
        <IonRouterOutlet>
          <Switch>
            <Route path="/landing" component={LandingPage} exact />
            <Route path="/verify" component={VerifyPage} exact />
            <Route path="/home" component={HomePage} exact />

            <Route path="/user/profile" component={ProfilePage} exact />
            <Route path="/user/inventory" component={InventoryPage} exact />
            <Route path="/user/transactions" component={TransactionsPage} exact />

            <Route path="/info/game-distribution" component={GameDistributionPage} exact />
            <Route path="/info/about" component={AboutPage} exact />
            <Route path="/info/release-notes" component={ReleaseNotesPage} exact />
            <Route path="/info/technology" component={TechnologyPage} exact />

            <Route path="/login" component={LoginPage } exact />
            <Route path="/register" component={RegisterPage} exact />

            <Route path="/game/gameplay" component={GameplayPage} exact />
            <Route path="/game/game-page" component={GamePage} exact />

            <Redirect exact from="/" to ="/landing" />
          </Switch>
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonApp>
  )
};

export default App;
