import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonMenu, IonRouterOutlet, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendar, homeOutline, informationCircle, map, personCircle } from 'ionicons/icons';

interface ContainerProps
{
    gameName: string;
    description: string;
    img: string;
}

const GameCardComponent: React.FC<ContainerProps> = ({gameName, description, img}) => {

  return (
    <IonCard>
        <IonImg className="game-img" src={img} />
        <IonImg className="game-badge" src="/assets/icon/badge.png" />
        <IonCardHeader className="">
            <IonBadge color="primary" className="ion-margin-start">Arcade</IonBadge>
            <IonBadge color="tertiary" className="ion-margin-start">Endless</IonBadge>

            <IonCardTitle className="white-text">
                {gameName}
            </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
            <IonText>
                
            </IonText>
            {description}
        </IonCardContent>
    </IonCard>


  );
};

export default GameCardComponent;
