import React, { useState } from "react";
import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonPage, IonRow, IonText, IonTitle, useIonToast } from "@ionic/react";

import './auth.css';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "../../appsettings.json";

interface FormData {
    firstName: string,
    lastName: string,
    email: string,
    username: string,
    password: string,
    confirmPassword: string
  }
  
  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: ""
  };
  
const RegisterPage : React.FC = () => {
    const [checked, setChecked] = useState(false);

    const [toastPresent, toastDismiss] = useIonToast();
    const { handleSubmit, control, errors, getValues } = useForm<FormData>({
        defaultValues: initialValues,
        mode: 'onChange'
    });

    const history = useHistory();

    const showErrors = (_fields: any) => {
        let error = (errors as any)[_fields];
        return error ? (
        <div style={{color: "red"}}>
            {error.message || "Field is required!"}
        </div>
        ) : null;
    };
  
    return (
        <IonPage>
            <IonContent>
            <form onSubmit={ handleSubmit(async (FormData) =>{
        const response = await fetch(api.Api + api.Endpoints.Register, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: FormData.firstName,
                lastName: FormData.lastName,
                userName: FormData.username,
                email: FormData.email,
                password: FormData.password,
                confirmPassword: FormData.confirmPassword
            })
            });

            const data = await response.json();

            if (data.success === true) {
            //alert(data.message);
            toastPresent({
                message: data.message,
                onDidDismiss: () => console.log('dismissed'),
                onWillDismiss: () => console.log('will dismiss'),
                duration: 3000,
                color: "success",
                keyboardClose : true,

            })
            history.push('/verify');
            } else {
            toastPresent({
                message: data.message,
                onDidDismiss: () => console.log('dismissed'),
                onWillDismiss: () => console.log('will dismiss'),
                duration: 3000,
                color: "danger",
                keyboardClose : true,

            })
            }
        })}>
                <IonGrid className="ion-no-padding">
                    <IonRow className="fullHeight ">
                        <IonCol className="leftCol" size-xs="12" size-md="6">
                            <IonGrid className="right-grid-contain">
                                <IonRow className="logo-padding ">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="none">
                                            <IonImg src="assets/icon/Xarcade-WHITE.svg"/>
                                        </IonButton>
                                        :
                                        <IonImg className="tiny-left-margin" src="assets/icon/Xarcade-WHITE.svg"/>
                                    }
                                    {
                                        (window.innerWidth < 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="none">
                                            <IonIcon icon="assets/icon/home.svg" className="back-to-home"></IonIcon>
                                        </IonButton>
                                        :
                                        null
                                    }
                                </IonRow>
                                <IonRow className="huge-margin bottom-margin desc">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonImg className="square-bits-scattered" src="assets/square-bits-scattered.svg"/>
                                        :
                                        null
                                    }
                                    <IonText className="ion-padding-horizontal text-box">
                                        Simple, direct distribution
                                        of games for both gamer
                                        and developer.
                                    </IonText>
                                </IonRow>
                                <IonRow className="huge-margin bottom-margin desc">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonText className="ion-padding-horizontal desc-half">
                                            The Xarcade platform distributes video games
                                            that are developed based on
                                            Sirius Platform technology.
                                        </IonText>
                                        :
                                        null
                                    }
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                        <IonCol className="rightCol" size-xs="12"  size-md="6">
                            <IonGrid className="grid-contain">
                                {
                                    (window.innerWidth > 600) ?
                                    <IonImg className="reg-page-pc" src="assets/register-page-pc.svg"/>
                                    :
                                    <IonImg className="square-bits-lined" src="assets/square-bits-lined.png"/>
                                }
                                <IonRow className="ion-justify-content-center large-margin">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonTitle className="ion-text-center ion-align-self-center welcome-back-text">
                                            Create Your Account
                                        </IonTitle>
                                        :
                                        <IonTitle className="ion-text-left welcome-back-text ion-no-padding">
                                            Create Your Account
                                        </IonTitle>
                                    }
                                </IonRow>
                                <IonRow className="ion-justify-content-center large-margin">
                                    <IonItem className="bordered-input">
                                        <IonIcon src="/assets/icon/first-name.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            render={({ onChange }) => (<IonInput type="text" placeholder="First Name" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("firstName")}

                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon src="/assets/icon/last-name.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            render={({ onChange }) => (<IonInput type="text" placeholder="Last Name" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("lastName")}
                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon src="/assets/icon/email-at.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="email"
                                            render={({ onChange }) => (<IonInput type="email" placeholder="Email Address" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "invalid email address"
                                                }
                                              }}
                                        />
                                    </IonItem>
                                    {showErrors("email")}
                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon  src="/assets/icon/user.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="username"
                                            render={({ onChange }) => (<IonInput type="text" placeholder="Username" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("username")}
                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon src="/assets/icon/padlock.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="password"
                                            render={({ onChange }) => (<IonInput type="password" placeholder="Password" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("password")}
                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon src="/assets/icon/confirm-padlock.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="confirmPassword"
                                            render={({ onChange }) => (<IonInput type="password" placeholder="Confirm Password" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{
                                                required: true,
                                                validate: {
                                                  verifyPassword: value => (value === getValues().password) || 'Passwords do not match!'
                                                }
                                            }}
                                        />
                                    </IonItem>
                                    {showErrors("confirmPassword")}
                                    <IonItem className="checkbox-input tiny-margin background-white">
                                        <Controller
                                            control={control}
                                            name="agreeToTerms"
                                            render={({ onChange }) => ( <IonCheckbox className="background-white" onIonChange={onChange}/>)}
                                            rules={{ required: true }}
                                        />
                                        <IonText color="medium" className="ion-padding-start"> I agree to the <IonText color="primary" className="link" >terms and conditions</IonText></IonText> 
                                    </IonItem>
                                    {showErrors("agreeToTerms")}
                                    <IonButton className="small-margin form-button" type="submit">Get Started</IonButton>
                                </IonRow>
                                <IonRow className="ion-justify-content-center large-margin ">
                                    {
                                        (window.innerWidth) > 600 ?
                                        <IonTitle className="ion-text-center ion-align-self-center create-account">
                                            Already have an account?
                                        </IonTitle>
                                        :
                                        <IonTitle className="ion-text-left ion-align-self-center create-account ion-no-padding">
                                            Already have an account?
                                        </IonTitle> 
                                    }

                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-no-padding">
                                    {
                                        (window.innerWidth) > 600 ?
                                        <IonButton routerLink="/login" fill="clear" color="light" className=" ion-no-padding">
                                            <IonText className="ion-text-center ion-align-self-center link create-account ion-text-capitalize ">
                                                Login here →
                                            </IonText>
                                        </IonButton>
                                        :
                                        <IonButton routerLink="/login" fill="clear" color="light" className="ion-no-padding">
                                            <IonText className="ion-text-left ion-align-self-start link create-account ion-text-capitalize ">
                                                Login here →
                                            </IonText>
                                        </IonButton>
                                    }

                                </IonRow>
                                <IonRow className="ion-justify-content-center huge-margin">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="light" className="back-to-home-footer">
                                            <IonIcon icon="assets/icon/home.svg" className="back-to-home"></IonIcon>
                                            <IonText className="ion-padding-start ion-text-capitalize back-to-home">
                                                Back to home
                                            </IonText>
                                        </IonButton>
                                        :
                                        null
                                    }
                                    <IonText color="medium" className="ion-text-center ion-align-self-center footer">
                                        © Copyright 2021. Xarcade. All rights reserved. | Powered by ProximaX®
                                    </IonText>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>

                </IonGrid>
                </form>
            </IonContent>


        </IonPage>
    );
};

export default RegisterPage;
