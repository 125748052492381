import React from "react";
import { IonAvatar, IonBadge, IonButton, IonCard, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonPopover } from "@ionic/react";
import './info.css';
import { codeSlashOutline, menuOutline, playCircleOutline } from "ionicons/icons";
import LandingHeaderComponent from "../../components/landing-header-component";
import LandingFooterComponent from "../../components/landing-footer-component";

const GameDistributionPage: React.FC = () => {

    return (

        <IonPage>
            <IonContent id="menuContent" >
                <LandingHeaderComponent/>                
                <IonGrid className="ion-no-padding">
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5"   size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        We bring gamers to your games
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </IonText>
                                </p>

                                <p  >
                                    <IonButton className="info-create-account-button" color="primary" href="https://app.xarcade.io/api-dashboard/register.html">
                                        <IonIcon icon={codeSlashOutline} color="light" className="ion-padding-end" ></IonIcon>
                                        <p className="ion-text-left">
                                            <IonText className="info-button-title">Create an Account for FREE</IonText><br/>
                                            <IonText className="info-button-subtitle">And start distributing your games</IonText>
                                        </p>
                                    </IonButton>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size="4" className="">

                        </IonCol>
                    </IonRow>

                    <IonRow className="landing-row background-dark-tint ">
                        <IonCol size-md="11" offset-md="0.5" size-xs="11" className="ion-margin">
                            <IonRow>
                                <IonCol size-md="6" offset-md="3">
                                    <div className="ion-text-center ion-padding-vertical">
                                        <h2 className="ion-no-margin">
                                            <IonText className="landing-title-text ion-no-margin ">
                                                Game Distribution Made Simple and Easy
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-subtitle-text ion-justify-content-center">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                            </IonText>
                                        </p>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonGrid className="ion-margin">
                                <IonRow className="ion-text-center ion-justify-content-center   ion-margin">
                                    <IonCol className="ion-align-items-center ion-margin" size-md="3" size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">1</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Account Registration
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                            </IonText>
                                        </p>
                                        <p  className="landing-play-game-button">
                                            
                                            <IonText className="landing-bold" color="primary">
                                                <IonIcon icon={playCircleOutline} color="primary" className="ion-margin-end" ></IonIcon> 
                                                Watch Video
                                            </IonText>
                                        </p>
                                    </IonCol>
                                    <IonCol className="ion-align-items-center ion-margin ion-padding-top" size-md="3" size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">2</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Token Creation
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                                            </IonText>
                                        </p>
                                        <p  className="landing-play-game-button">
                                            
                                            <IonText className="landing-bold" color="primary">
                                                <IonIcon icon={playCircleOutline} color="primary" className="ion-margin-end" ></IonIcon> 
                                                Watch Video
                                            </IonText>
                                        </p>
                                    </IonCol>
                                    <IonCol className="ion-align-items-center ion-margin" size-md="3" size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">3</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Game Registration
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.
                                            </IonText>
                                        </p>
                                        <p  className="landing-play-game-button">
                                            
                                            <IonText className="landing-bold" color="primary">
                                                <IonIcon icon={playCircleOutline} color="primary" className="ion-margin-end" ></IonIcon> 
                                                Watch Video
                                            </IonText>
                                        </p>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol size-md="6" offset-md="3">
                            <div className="ion-text-center ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Get Dicovered Instantly
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                    </IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5" size-xs="11" className="ion-margin">
                            <div className="ion-text-start ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                    Engaged Gamers
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text  ion-justify-content-center">
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Check Gamer's Perks→</IonText>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size-md="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5" size-xs="11">

                        </IonCol>
                        <IonCol size-md="5" offset-md="1" size-xs="11"className="ion-margin">
                            <div className="ion-text-start ">
                                <p className="ion-no-margin"><IonLabel color="danger">OUR MISSION</IonLabel></p>
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Equal Chance of Discovery
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Read more about Xarcade Algorithm →</IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark">
                    <IonCol size-md="4" offset-md="1.5"  size-xs="11" className="ion-margin">
                        <div className="ion-text-start ion-padding">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        We Do the Marketing
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text  ion-justify-content-center">
                                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Get Listed and Notified →</IonText>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size-md="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark-tint">
                        <IonCol size-md="4" offset-md="1.5"  size-xs="11" >

                        </IonCol>
                        <IonCol size-md="5" offset-md="1" size-xs="11" className="ion-margin">
                            <div className="ion-text-start ion-padding ion-margin-vertical">
                                <p className="ion-no-margin"><IonLabel color="danger">XAR BOOST FEATURE</IonLabel></p>
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        You'll Get Boosted!
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla sint occaecat cupidatat non proident, sunt in culpa qui pariatur.
                                    </IonText>
                                </p>
                                <p  >
                                    <IonButton className="info-create-account-button" color="primary" href="https://app.xarcade.io/api-dashboard/register.html">
                                        <IonIcon icon={codeSlashOutline} color="light" className="ion-padding-end" ></IonIcon>
                                        <p className="ion-text-left">
                                            <IonText className="info-button-title">Create an Account for FREE</IonText><br/>
                                            <IonText className="info-button-subtitle">And start distributing your games</IonText>
                                        </p>
                                    </IonButton>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="info-half-row background-dark ion-padding">
                        <IonCol className="ion-margin ion-padding info-card" size-md="3" offset-md="1">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                                <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Introducing XAR
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Duis aute irure dolor in reprehenderit in voluptate velit esse.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol   className="ion-margin ion-padding  info-card" size-md="3">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Xarcade Technology
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Ut enim ad minima veniam, quis nostrum exercitationem ullam.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                        <IonCol  className="ion-margin ion-padding  info-card" size-md="3">
                            <IonAvatar className="ion-margin-top ion-margin-start">
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                            </IonAvatar>
                            <h2 className=" ion-margin-start">
                                <IonText className="info-card-title ion-no-margin ">
                                    Gamer's Perks
                                </IonText>
                            </h2>
                            <p  className=" ion-margin-start">
                                <IonText className="landing-image-box-text ion-justify-content-center">
                                Ut enim ad minima veniam, quis nostrum exercitationem ullam.
                                </IonText>
                            </p>
                            <p  className="landing-play-game-button ion-margin-start">
                                
                                <IonText className="landing-bold" color="primary">
                                    Learn More →
                                </IonText>
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <LandingFooterComponent/>
            </IonContent>
        </IonPage>
    );
};

export default GameDistributionPage;
