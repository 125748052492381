import React from "react";
import { IonButton, IonContent, IonPage, IonTitle } from "@ionic/react";
const TransactionsPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent className="ion-padding">
                <IonTitle className="ion-padding">Profile Page</IonTitle>

                <IonButton routerLink="/register">Register</IonButton>
                <IonButton routerLink="/login">Login</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default TransactionsPage;
