import React from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonPage, IonTitle } from "@ionic/react";
const VerifyPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent >
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>An email is on its way!</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        Click "verify", so you're able to log in!
                    </IonCardContent>
                    <IonButton expand="block"  routerLink="/login">Login</IonButton>

                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default VerifyPage;
