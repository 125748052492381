import React, { useState, useEffect } from "react";
import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonRouterOutlet, IonRow, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonThumbnail, IonTitle, IonToolbar } from "@ionic/react";
import '../home.css';
import HeaderComponent from "../../components/header-component";
import FooterComponent from "../../components/footer-component";
import Iframe from 'react-iframe';
import api from "../../appsettings.json";

const TestPage: React.FC = () => {
    let gameId = localStorage.getItem('gameId');
    let releaseName = localStorage.getItem('gameName');
    let gameLink = api.Dashboard + "blob/releases/" + gameId + "/" + releaseName + "/index.html";

    return (
            <IonContent id="menuContent">
                <IonPage>
                    <HeaderComponent  />
                    {/* <Iframe url="https://xarcade.proximaxtest.com/blob/html5game2/index.html"
                    width="100%"
                    height="100%"
                    id="myId"
                    className="myClassname"
                    display="block"
                    position="relative"/> */}
                    <object data={gameLink}
                    width="100%"
                    height="100%"
                    type="text/html">
                        Alternative Content
                    </object>
                    <FooterComponent/>

                </IonPage>

            </IonContent>
    );
};

export default TestPage;
