import { IonFooter, IonToolbar, IonLabel, IonGrid, IonRow, IonCol, IonListHeader, IonList, IonItem, IonIcon, IonInput, IonButton, IonImg } from '@ionic/react';
import { logoFacebook, logoTwitter, logoInstagram, logoReddit, logoMedium, logoDiscord, paperPlaneOutline, logoYoutube } from 'ionicons/icons';
import "./landing-footer-component.css";

const LandingFooterComponent: React.FC = () => {
    return (
        <IonFooter className="footer-background ">
            <IonToolbar className="ion-no-border">
                <IonGrid className="max-width-1400">
                    <IonRow className="large-margin ">
                        <IonCol push-xs="0.5" size-xs="11" size-md="5">
                            <IonLabel color="light"> Stay up-to-date with our latest news and announcements </IonLabel>
                            <IonInput type="email" placeholder="Enter your email" className="email-input">
                                <IonButton type="submit" className="button-input"> Subscribe </IonButton>
                            </IonInput>
                        </IonCol>
                        <IonCol  push-md="5">
                            <IonImg className="xarcade-logo" src="assets/icon/xarcade.png"/>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-self-start ">
                        <IonCol className="list-margin">
                            <IonList>
                                <IonListHeader className="footer-list-header"> About Xarcade </IonListHeader>
                                <IonItem button className="footer-list-text" lines="none"> About </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Blog </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Brand Assets </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Badges </IonItem>
                            </IonList>
                        </IonCol>
                        <IonCol className="list-margin">
                            <IonList>
                                <IonListHeader className="footer-list-header"> Get Started </IonListHeader>
                                <IonItem href="#" className="footer-list-text" lines="none"> What is Xarcade? </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> What is XAR? </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> How can I earn rewards? </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Play Games </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Join the Community </IonItem>
                            </IonList>
                        </IonCol>
                        <IonCol className="list-margin">
                            <IonList>
                                <IonListHeader className="footer-list-header"> Developers </IonListHeader>
                                <IonItem href="#" className="footer-list-text" lines="none"> Distribution Guide </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Technology </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Release Notes </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> My Account </IonItem>
                            </IonList>
                        </IonCol>
                        <IonCol className="list-margin">
                            <IonList>
                                <IonListHeader className="footer-list-header"> Support Center </IonListHeader>
                                <IonItem href="#" className="footer-list-text" lines="none"> Frequently Asked Questions </IonItem>
                                <IonItem href="#" className="footer-list-text" lines="none"> Get In Touch </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-no-padding ion-no-margin">
                        <IonCol size-xs="9">
                            <IonLabel className="footer-list-text"> Terms & Conditions </IonLabel>
                            <IonLabel className="footer-list-text"> Privacy Policy </IonLabel>
                        </IonCol>
                        <IonCol push-md="3" size-xs="8">
                            <IonIcon className="footer-icon" icon={logoFacebook}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoTwitter}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoInstagram}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoReddit}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoMedium}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoDiscord}></IonIcon>
                            <IonIcon className="footer-icon" icon={paperPlaneOutline}></IonIcon>
                            <IonIcon className="footer-icon" icon={logoYoutube}></IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonRow className="">
                        <IonCol offset-md="">
                            <IonLabel className="footer-list-text"> © Copyright 2021 Xarcade. All rights reserved. </IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter>
    );
};

export default LandingFooterComponent;