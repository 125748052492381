import React from "react";
import { IonAvatar, IonBadge, IonButton, IonCard, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonPopover } from "@ionic/react";
import './info.css';
import { codeSlashOutline, listOutline, menuOutline, playCircleOutline } from "ionicons/icons";
import LandingHeaderComponent from "../../components/landing-header-component";
import LandingFooterComponent from "../../components/landing-footer-component";

const ReleaseNotesPage: React.FC = () => {

    return (

        <IonPage>
            <IonContent id="menuContent" >
                <LandingHeaderComponent/>                
                <IonGrid className="ion-no-padding">
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5"   size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                    <IonIcon color="white" icon={listOutline}></IonIcon> Release Notes 
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </IonText>
                                </p>


                            </div>
                        </IonCol>
                        <IonCol size="4" className="">

                        </IonCol>
                    </IonRow>





                </IonGrid>
                <LandingFooterComponent/>
            </IonContent>
        </IonPage>
    );
};

export default ReleaseNotesPage;
