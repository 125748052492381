import { IonButton, IonHeader, IonIcon, IonImg, IonItem, IonItemGroup, IonAvatar, IonList, IonLabel, IonMenuButton, IonToolbar, useIonPopover, IonText, IonChip, IonFooter, IonRow, IonGrid } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  menuOutline, chevronDownOutline, exitOutline, helpCircleOutline, notificationsCircleOutline, notificationsOutline } from 'ionicons/icons';
import './header-footer-component.css';
import LandingTabsComponent from './landing-tabs';




const FooterComponent: React.FC = () => {

    return (
        <>
        {
            (window.innerWidth > 600) ?
            <IonFooter>
                <IonToolbar className="ion-padding-horizontal ion-text-center header-toolbar">
                    <IonImg slot="start" className="footer-logo ion-margin" src="assets/icon/xarcade.png"/>
                    <IonText color="light" className="footer-text"slot="start">Copyright 2021 Xarcade All rights reserved. Powered by ProximaX</IonText>
                    <IonText color="light" className=""slot="end">Follow us on</IonText>

                </IonToolbar>
            </IonFooter>
            :
            <IonFooter>
                <IonToolbar className=" landing-toolbar">
                    <LandingTabsComponent/>

                    <IonMenuButton  color="light" slot="end">
                        <IonIcon icon={menuOutline}  slot="start" color="light"/> 
                    </IonMenuButton>
                    <IonImg slot="start" className="landing-logo ion-margin-end " src="assets/icon/xarcade.png"/>
                </IonToolbar>
            </IonFooter>

        }
        </>
    );
};

export default FooterComponent;
