export function CheckLoginValid(jwtToken: any)
{
    let date = new Date();
    let expiry = localStorage.getItem('expiry');
    let valid = true;

    if (jwtToken === '' || jwtToken === null) {
        valid = false;
    } else if (expiry !== null && parseInt(expiry) < date.setHours(date.getHours())){
        valid = false;
    }
    
    return valid;
}