import React, { useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar, isPlatform, useIonToast } from "@ionic/react";
import './auth.css';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "../../appsettings.json";

interface FormData {
    username: string,
    password: string
}

let initialValues = {
    username: "",
    password: ""
};
const LoginPage: React.FC = () => {
     const [toastPresent, toastDismiss] = useIonToast();

    const { handleSubmit, control, errors } = useForm<FormData>({
        defaultValues: initialValues,
        mode: 'onChange'
    });

    const history = useHistory();

    const showErrors = (_fields: any) => {
    let error = (errors as any)[_fields];
    return error ? (
        <div style={{color: "red"}}>
        {error.message || "Field is required!"}
        </div>
    ) : null;
    };
    
    const [checked, setChecked] = useState(false);
    return (
        
        <IonPage>

            <IonContent>
            <form onSubmit={ handleSubmit(async(FormData) => {
                const response = await fetch(api.Api + api.Endpoints.Login, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName: FormData.username,
                    password: FormData.password
                })
                });
                const data = await response.json();
                if (data.success === true) {
                    let date = new Date();
                    let expiry = date.setHours(date.getHours() + 1);
                    let visitedPage = sessionStorage.getItem('visitedPage') || null;

                    localStorage.setItem("userData", JSON.stringify(data["authenticationData"]["account"]));
                    localStorage.setItem("jwtToken", JSON.stringify(data["authenticationData"]["jwtToken"]));
                    localStorage.setItem("expiry", expiry.toString());

                    toastPresent({
                        message: data.message,
                        onDidDismiss: () => console.log('dismissed'),
                        onWillDismiss: () => console.log('will dismiss'),
                        duration: 3000,
                        color: "success",
                        keyboardClose : true,
                    })

                    if (visitedPage) {
                        history.push(visitedPage.replace('#', ''));
                    } else {
                        history.push('/home')
                    }
                } else {
                    toastPresent({
                        message: data.message,
                        onDidDismiss: () => console.log('dismissed'),
                        onWillDismiss: () => console.log('will dismiss'),
                        duration: 3000,
                        color: "danger",
                        keyboardClose : true,
            
                    })
                }
            }) }>
                <IonGrid className="ion-no-padding">
                    <IonRow className="fullHeight ">
                        <IonCol className="leftCol-login" size-xs="12" size-md="6">
                            <IonGrid className="right-grid-contain">
                                <IonRow className="logo-padding ">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="none">
                                            <IonImg src="assets/icon/Xarcade-WHITE.svg"/>
                                        </IonButton>
                                        :
                                        <IonImg className="tiny-left-margin" src="assets/icon/Xarcade-WHITE.svg"/>
                                    }
                                    {
                                        (window.innerWidth < 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="none">
                                            <IonIcon icon="assets/icon/home.svg" className="back-to-home"></IonIcon>
                                        </IonButton>
                                        :
                                        null
                                    }
                                </IonRow>
                                <IonRow className="huge-margin bottom-margin desc">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonImg className="square-bits-scattered" src="assets/square-bits-scattered.svg"/>
                                        :
                                        <IonImg className="square-bits-lined" src="assets/square-bits-lined.png"/>
                                    }
                                    <IonText className="ion-padding-horizontal">
                                        Simple, direct distribution
                                        of games for both gamer
                                        and developer.
                                    </IonText>
                                </IonRow>
                                <IonRow className="huge-margin bottom-margin desc">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonText className="ion-padding-horizontal desc-half">
                                            The Xarcade platform distributes video games
                                            that are developed based on
                                            Sirius Platform technology.
                                        </IonText>
                                        :
                                        null
                                    }
                                </IonRow>
                            </IonGrid>
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonImg className="blue-line-login" src="assets/blue-line.svg"/>
                                        :
                                        null
                                    }
                        </IonCol>
                        <IonCol className="rightCol" size-xs="12"  size-md="6">
                            <IonGrid className="grid-contain">
                                {
                                    (window.innerWidth > 600) ?
                                    <IonImg className="reg-page-pc" src="assets/register-page-pc.svg"/>
                                    :
                                    null
                                }
                                <IonRow className ="ion-justify-content-center logo-padding">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonImg className="logo" src="/assets/icon/xarcade.png" />
                                        :
                                        null
                                    }
                                </IonRow>
                                <IonRow className="ion-justify-content-center large-margin">
                                    {
                                        (window.innerWidth > 600) ?

                                        <IonTitle className="ion-text-center ion-align-self-center welcome-back-text">
                                            Welcome Back!
                                        </IonTitle>
                                        :
                                        <IonTitle className="ion-text-left welcome-back-text ion-no-padding">
                                            Welcome Back!
                                        </IonTitle>
                                    }
                                </IonRow>
                                <IonRow className="ion-justify-content-center large-margin">
                                    <IonItem className="bordered-input">
                                        <IonIcon src="/assets/icon/user.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="username"
                                            render={({ onChange }) => (<IonInput type="text" placeholder="Username" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("username")}
                                    <IonItem className="bordered-input tiny-margin ">
                                        <IonIcon src="/assets/icon/padlock.svg"></IonIcon>
                                        <Controller
                                            control={control}
                                            name="password"
                                            render={({ onChange }) => (<IonInput type="password" placeholder="Password" className="ion-margin-start" onIonChange={onChange}></IonInput>)}
                                            rules={{ required: true }}
                                        />
                                    </IonItem>
                                    {showErrors("password")}
                                    <IonItem lines="none" className="tiny-margin ion-no-padding background-white">
                                        <IonGrid className="grid-contain">
                                            <IonRow>
                                                <IonCol  className="" size="1" >
                                                    <IonCheckbox className="background-white" checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                                                </IonCol>
                                                <IonCol className="ion-padding-vertical sm-font"size="5" >
                                                    <IonText color="medium" >Remember me</IonText>
                                                </IonCol>
                                                <IonCol size="6" className="ion-text-end ion-padding-vertical sm-font ">
                                                    <IonText className="link ">Forgot Password?</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>
                                    <IonButton  type="submit" className="small-margin form-button">Login</IonButton>
                                </IonRow>
                                <IonRow className="ion-justify-content-center huge-margin ">
                                    {
                                        (window.innerWidth) > 600 ?
                                        <IonTitle className="ion-text-center ion-align-self-center create-account">
                                            No account yet?
                                        </IonTitle>
                                        :
                                        <IonTitle className="ion-text-left ion-align-self-center create-account ion-no-padding">
                                            No account yet?
                                        </IonTitle> 
                                    }

                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-no-padding">
                                    <IonButton routerLink="/register" fill="clear" color="light" className=" ion-no-padding">
                                        <IonText className="ion-text-center ion-align-self-center link create-account ion-text-capitalize ">
                                            Create an account here →
                                        </IonText>
                                    </IonButton>
                                </IonRow>

                                <IonRow className="ion-justify-content-center huge-margin">
                                    {
                                        (window.innerWidth > 600) ?
                                        <IonButton routerLink="/landing" fill="clear" color="light" className="back-to-home-footer">
                                            <IonIcon icon="assets/icon/home.svg" className="back-to-home"></IonIcon>
                                            <IonText className="ion-padding-start ion-text-capitalize back-to-home">
                                                Back to home
                                            </IonText>
                                        </IonButton>
                                        :
                                        null
                                    }
                                    <IonText color="medium" className="ion-text-center ion-align-self-center footer">
                                        © Copyright 2021. Xarcade. All rights reserved. | Powered by ProximaX®
                                    </IonText>

                                </IonRow>
                            </IonGrid>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </form>

            </IonContent>


        </IonPage>
    );
};

export default LoginPage;
