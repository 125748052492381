import { IonButton, IonHeader, IonIcon, IonImg, IonItem, IonItemGroup, IonAvatar, IonList, IonLabel, IonMenuButton, IonToolbar, useIonPopover, IonText } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  menuOutline, chevronDownOutline, exitOutline } from 'ionicons/icons';
import './home-tabs.css';
import LandingTabsComponent from './landing-tabs';

const DeveloperPopoverList: React.FC<{
    onHide: () => void;
}> = ({ onHide }) => (
    <IonList className="header-mouse-over">
        <IonItemGroup>
            <IonItem lines="none" button href="#/info/game-distribution" routerDirection="forward" onClick={onHide}>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    Distribution Guide
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> Everything you'll need to get you started </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
        <IonItemGroup>
            <IonItem lines="none" button href="#/info/technology" routerDirection="forward" onClick={onHide}>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    Technology
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> See what's next on the development </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
        <IonItemGroup>
            <IonItem lines="none" button href="#/info/release-notes" routerDirection="forward" onClick={onHide}>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    Release Notes
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> Your guide on the detailed updates on the platform </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
        <IonItemGroup>
            <IonItem lines="none" button href="https://app.xarcade.io/api-dashboard/register.html" routerDirection="forward" onClick={onHide}>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    Developer's Account <IonIcon icon={exitOutline}  slot="end" color="dark"/> 
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> A dashboard to distribute your games </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
    </IonList>
);

const GettingStartedPopoverList: React.FC<{
    onHide: () => void;
}> = () => (
    <IonList className="header-mouse-over">
        <IonItemGroup>
            <IonItem lines="none" button>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    What is Xarcade?
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> Learn more about Xarcade </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
        <IonItemGroup>
            <IonItem lines="none" button>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    What is XAR?
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> A stable utility coin of Xarcade </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
        <IonItemGroup>
            <IonItem lines="none" button>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    How can I earn rewards?
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> Learn how to get your rewards </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
    </IonList>
);

const SupportPopoverList: React.FC<{
    onHide: () => void;
}> = () => (
    <IonList className="header-mouse-over">
        <IonItemGroup>
            <IonItem lines="none" button>
                <IonAvatar>
                    <img src="assets/blank-image.png"/>
                </IonAvatar>
                <IonLabel className="ion-text-wrap mouse-over-title-text"> 
                    Frequently Asked Questions
                    <IonText className="ion-text-wrap mouse-over-title-subtext"><br/> Learn more details about the platform </IonText>
                </IonLabel>
            </IonItem>
        </IonItemGroup>
    </IonList>
);

const LandingHeaderComponent: React.FC = () => {
    const [presentDeveloper, dismiss] = useIonPopover(DeveloperPopoverList, { onHide: () => dismiss() });
    const [presentGettingStarted] = useIonPopover(GettingStartedPopoverList, { onHide: () => dismiss() });
    const [presentSupport] = useIonPopover(SupportPopoverList, { onHide: () => dismiss() });

    return (
        <>
        {
            (window.innerWidth > 600) ?
            <IonToolbar className="ion-padding-horizontal ion-text-center landing-toolbar">
                <IonImg slot="start" className="landing-logo ion-margin-end" src="assets/icon/Xarcade-RGB.png"/>
                <IonButton routerLink="/home" routerDirection="forward" slot ="end" color="light" fill="clear" className="header-button">Games</IonButton>
                <IonButton
                    slot ="end"
                    color="light"
                    fill="clear"
                    expand="block"
                    className="header-button"
                    onClick={(e) =>
                        presentDeveloper({
                            event: e.nativeEvent,
                        })
                    }
                > Developers <IonIcon icon={chevronDownOutline}  slot="end" color="light"/> 
                </IonButton>
                <IonButton
                    slot ="end"
                    color="light"
                    fill="clear"
                    expand="block"
                    className="header-button"
                    onClick={(e) =>
                        presentGettingStarted({
                            event: e.nativeEvent,
                        })
                    }
                > Getting Started <IonIcon icon={chevronDownOutline}  slot="end" color="light"/> 
                </IonButton>
                <IonButton
                    slot ="end"
                    color="light"
                    fill="clear"
                    expand="block"
                    className="header-button"
                    onClick={(e) =>
                        presentSupport({
                            event: e.nativeEvent,
                        })
                    }
                > Support <IonIcon icon={chevronDownOutline}  slot="end" color="light"/> 
                </IonButton>
                <IonButton slot ="end" color="light" fill="clear">More</IonButton>
            </IonToolbar>
            :
            <IonToolbar className=" landing-toolbar">
                <IonMenuButton  color="light" slot="end">
                    <IonIcon icon={menuOutline}  slot="start" color="light"/> 
                </IonMenuButton>
                <IonImg slot="start" className="landing-logo ion-margin-end " src="assets/icon/Xarcade-RGB.png"/>
            </IonToolbar>
        }
        </>
    );
};

export default LandingHeaderComponent;
