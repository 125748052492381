import { IonBadge, IonButton, IonCard, IonCardSubtitle, IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonRouterOutlet, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendar, diamondOutline, gitCompareOutline, globeOutline, homeOutline, informationCircle, map, personCircle, personOutline } from 'ionicons/icons';
import './home-tabs.css';

interface ComponentProps
{
  active : string;
}

const HomeTabsComponent: React.FC<ComponentProps> = ({active}) => {


  return (

      <IonMenu side="start" menuId="first" content-id="menuContent" id="split-pane" >
        <IonHeader className="">
          <IonToolbar className="side-menu ion-padding-top" >
            <IonImg className="logo  ion-padding" src="/assets/icon/xarcade-logo-white.png" />

          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {
              (active == "home") ? 
              <IonItem button routerLink="/home" className="home-tab-item home-tab-active" type="button" lines="none"><IonIcon icon={homeOutline} slot="start" color="white"></IonIcon> Home</IonItem>
              :
              <IonItem button routerLink="/home" className="home-tab-item" type="button" lines="none"><IonIcon icon={homeOutline} slot="start" color="white"></IonIcon> Home</IonItem>
            }
            {
              (active == "profile") ? 
              <IonItem button routerLink="/user/profile" className="home-tab-item home-tab-active" lines="none"><IonIcon icon={personOutline}  slot="start" color="white"/> Profile</IonItem>
              :
              <IonItem button routerLink="/user/profile" className="home-tab-item" lines="none"><IonIcon icon={personOutline}  slot="start" color="white"/> Profile</IonItem>

            }
            {
              (active == "inventory") ? 
              <IonItem button routerLink="/user/inventory" className="home-tab-item home-tab-active" lines="none"><IonIcon icon={diamondOutline}  slot="start" color="white"/> Inventory</IonItem>
              :
              <IonItem button routerLink="/user/inventory" className="home-tab-item" lines="none"><IonIcon icon={diamondOutline}  slot="start" color="white"/> Inventory</IonItem>

            }
            {
              (active == "transactions") ? 
              <IonItem button routerLink="/user/transactions" className="home-tab-item home-tab-active" lines="none"><IonIcon icon={gitCompareOutline}  slot="start" color="white"/> Transaction List</IonItem>
              :
              <IonItem button routerLink="/user/transactions" className="home-tab-item" lines="none"><IonIcon icon={gitCompareOutline}  slot="start" color="white"/> Transaction List</IonItem>
            }
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar className="ion-margin-bottom ion-text-center">
            <IonButton routerLink="/landing" shape="round" expand="block" className="ion-padding-horizontal" fill="outline" color="light">Sign out</IonButton>
            <IonButton routerLink="/landing" shape="round" size="small"fill="clear" color="light"><IonIcon icon={globeOutline}  slot="start" color="white"/>Main website</IonButton>

          </IonToolbar>
        </IonFooter>
      </IonMenu>

  );
};

export default HomeTabsComponent;
