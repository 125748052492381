import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonLabel, IonRouterLink, IonIcon, IonSlides, IonSlide, IonImg, IonButton } from "@ionic/react";
import { arrowRedoOutline, bookmark, gameControllerOutline, globeOutline, heart, informationCircle, starOutline } from "ionicons/icons";
import { useLocation, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import './game-page.css';
import HeaderComponent from "../../components/header-component";
import FooterComponent from "../../components/footer-component";
import WelcomeModal from "../../components/welcome-modal";
import { CheckLoginValid } from "../../export-functions";
import api from "../../appsettings.json";

const slideOpts = {
    initialSlide: 1,
    speed: 400
};

export let GameTitleContext = React.createContext({
    gameTitle: '',
    gameHeader: '',
});

const GamePage: React.FC = () => {
    let [ gameDetails, setGameDetails ] = useState<any>({});
    let [ gamer, setGame ] = useState<any>({});

    let jwtToken = localStorage.getItem('jwtToken');
    let auth = CheckLoginValid(jwtToken);

    let location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    let params = Object.fromEntries(searchParams.entries());

    sessionStorage.setItem('visitedPage', window.location.hash);

    useEffect(() => {

        fetch(api.Api + api.Endpoints.Games.GetGameDetails + location.search, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(setGameDetails);

        fetch(api.Api + api.Endpoints.Games.GetGameDetails + location.search, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            localStorage.setItem('gameName', data.viewModel.game.name + '_' + data.viewModel.game.version.replaceAll('.', '_'));
            localStorage.setItem('gameId', searchParams.get('gameId') || '');
        })
        .then(setGame);

        return () => {
            setGameDetails({});
            setGame({});
        }
    }, []);

    const playGame = () => {
        const game = window.open("#" + "game/gameplay");
        game?.focus();
    }

    if (Object.keys(gameDetails).length === 0) {
        return (
            <IonContent> </IonContent>
        );
    } else if (!auth){
        return (
            <Redirect to ="/register"/>
        );
    } else {
        return (
            <IonContent id="menuContent">
                <HeaderComponent/>
                <IonGrid className="ion-no-margin ion-no-padding game-page-text">
                    <IonRow className="ion-padding background">
                    </IonRow>
                    <IonRow className="ion-padding ion-margin game-border p50">
                        <IonCol size="12">
                            <IonLabel className="game-title"> {gameDetails.viewModel.game.name} </IonLabel>
                        </IonCol>
                        <IonCol size="6">
                            <IonRow>
                                <IonCol size="2.5">
                                    <IonLabel>
                                        By <IonRouterLink className="game-company" href="#"> Watson Gaming <IonIcon className="icons-align" icon={informationCircle}></IonIcon></IonRouterLink>
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="1.5">
                                    <IonLabel className="text-gray"> Browser <IonIcon className="icons-align" icon={globeOutline} color="#AFAFAF"></IonIcon></IonLabel>
                                </IonCol>
                                <IonCol size="1.5">
                                    <IonLabel className="text-gray"> {gameDetails.viewModel.game.category} <IonIcon className="icons-align" icon={gameControllerOutline} color="#AFAFAF"></IonIcon></IonLabel>
                                </IonCol>
                                <IonCol size="2.5" className="ion-text-center">
                                    <IonLabel className="text-gray"> Share With Friends <IonIcon className="icons-align" icon={arrowRedoOutline} color="#AFAFAF"></IonIcon></IonLabel>
                                </IonCol>
                                <IonCol size="0.5">
                                    <IonLabel className="text-gray"><IonIcon className="icons-align" icon={heart} color="#AFAFAF"></IonIcon></IonLabel>
                                </IonCol>
                                <IonCol>
                                    <IonLabel className="text-gray"><IonIcon className="icons-align" icon={bookmark} color="#AFAFAF"></IonIcon></IonLabel>
                                </IonCol>
                                <IonCol className="ion-float-end" push="12">
                                    <IonButton onClick={playGame} tem-right>Play</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding ion-margin p-50">
                        <IonCol size="8">
                            <IonRow>
                                <IonCol>
                                    <IonSlides className="game-img-slides" pager={true} options={slideOpts}>
                                        {
                                            gameDetails.viewModel.details ?
                                            gameDetails.viewModel.details.gallery.galleryImages.map((items: any, index: any) => {
                                                let imgSrc = api.Dashboard + "blob/games/" + gameDetails.viewModel.game.gameId + "/gallery/" + items;
                                                return (
                                                    <IonSlide key={items}>
                                                        <IonImg className="game-img-slides" src={imgSrc}/>
                                                    </IonSlide>
                                                );
                                            })
                                            :
                                            ''
                                        }
                                    </IonSlides>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding ion-margin p-50">
                                <IonCol>
                                    <IonLabel className="game-subtitle">About This Game</IonLabel>
                                    <p className="game-text"> 
                                        {
                                            gameDetails.viewModel.details.about ?
                                            gameDetails.viewModel.details.about.about
                                            :
                                            ''
                                        } 
                                    </p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding ion-margin p-50">
                                <IonCol>
                                    <IonLabel className="game-subtitle">Game Features</IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="4">
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow>
                                        <IonCol className="ion-padding-start">
                                            <IonLabel className="game-headingtitle">Game Ratings & Reviews</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-padding" size="12">
                                            <IonLabel className="ion-margin-end game-subheadingtitle">All Reviews</IonLabel>
                                            <IonLabel className="ion-margin-start game-subheadingtitle">Overall Rating: Very Impressive!</IonLabel>
                                        </IonCol>
                                        <IonCol className="ion-padding" size="12">
                                            <IonLabel className="ion-margin-end game-subheadingtitle">Your Rating</IonLabel>
                                            <IonIcon className="ion-margin-start icons-align" icon={starOutline} color="#AFAFAF"></IonIcon>
                                            <IonIcon className="icons-align" icon={starOutline} color="#AFAFAF"></IonIcon>
                                            <IonIcon className="icons-align" icon={starOutline} color="#AFAFAF"></IonIcon>
                                            <IonIcon className="icons-align" icon={starOutline} color="#AFAFAF"></IonIcon>
                                            <IonIcon className="icons-align" icon={starOutline} color="#AFAFAF"></IonIcon>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow>
                                        <IonCol className="ion-padding-start">
                                            <IonLabel className="game-headingtitle">Game Details</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Last Update</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">November 4, 2021</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Content Rating</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle"> 
                                                {
                                                    gameDetails.viewModel.details ?
                                                    gameDetails.viewModel.game.contentRating
                                                    :
                                                    ''
                                                }
                                            </IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Release Date</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">November 1, 2021</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Plays</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">12,345</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Current Version</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">1.0.0</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Developer</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Watson Games</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Publisher</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Watson Games</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Platform</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Browser</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Compatible Browsers</IonLabel>
                                        </IonCol>
                                        <IonCol size="5">
                                            <IonLabel className="game-subheadingtitle">Chrome, Firefox, Safari, Opera, Edge</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Languages</IonLabel>
                                        </IonCol>
                                        {
                                            gameDetails.viewModel.game.languages ?
                                            gameDetails.viewModel.game.languages.map((items: any, index: any) => {
                                                let options = [];
                                    
                                                if (items.audio) {
                                                    options.push('Audio');
                                                }
                                    
                                                if (items.interface) {
                                                    options.push('Interface');
                                                }
                                    
                                                if (items.subtitles) {
                                                    options.push('Subtitles');
                                                }
                                    
                                                if (index === 0) {
                                                    return (
                                                        <IonCol size="5" key={items.name}>
                                                            <IonLabel className="game-subheadingtitle ion-text-capitalize">{items.name} - {options.join(", ")}</IonLabel>
                                                        </IonCol>
                                                    )
                                                } else {
                                                    return (
                                                        <IonCol size="5" offset="3" key={items.name}>
                                                            <IonLabel className="game-subheadingtitle ion-text-capitalize">{items.name} - {options.join(", ")}</IonLabel>
                                                        </IonCol>
                                                    )
                                                }
                                            })
                                            :
                                            ''
                                        }
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">In-Game Token</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">YES</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom game-border">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Tags</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">{gameDetails.viewModel.game.tags ? gameDetails.viewModel.game.tags.join(", ") : ''}</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-bottom">
                                <IonCol>
                                    <IonRow className="ion-padding">
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Report</IonLabel>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonLabel className="game-subheadingtitle">Report This Game</IonLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <FooterComponent/>
                {
                    params.isFirstTime !== undefined && params.isFirstTime === 'true' ?
                    <GameTitleContext.Provider value={
                        {
                            gameTitle: gameDetails.viewModel.game.name,
                            gameHeader: gameDetails.viewModel.details.gallery.headerImage,
                        }
                    }>
                        <WelcomeModal/>
                    </GameTitleContext.Provider>
                    :
                    ''
                }
            </IonContent>
        );
    }
};

export default GamePage;
