import React, { useState } from 'react';
import { IonModal, IonContent, IonButton, IonGrid, IonRow, IonCol, IonImg, IonCard } from '@ionic/react';
import './welcome-modal.css';
import { useHistory } from 'react-router';
import { GameTitleContext } from "../pages/game/game-page";
import api from "../appsettings.json";

const WelcomeModal: React.FC = () => {
    const [showModal, setShowModal] = useState(true);
    const history = useHistory();

    let userData = JSON.parse(localStorage.getItem('userData') || '');
    let gameId = localStorage.getItem('gameId');
    let gameDetails = React.useContext(GameTitleContext);
    let headerImage = api.Dashboard + 'blob/games/' + gameId + '/gallery/' + gameDetails.gameHeader;

    return (
        <IonContent>
            <IonGrid className="ion-no-margin ion-no-padding">
                <IonModal isOpen={showModal} cssClass="welcome-modal">
                    <IonRow className="centered">
                        <IonCol>
                            <h1 className="mt-50 mb-0">Welcome, {userData.username}!</h1>
                        </IonCol>
                    </IonRow>
                    <IonRow className="centered">
                        <IonCol>
                            <h3 className="mt-0 invitation-text">You are invited to test the game "{gameDetails.gameTitle}".</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="centered">
                        <IonCol className="center-col">
                            <IonCard>
                                <IonImg src={headerImage} className="img-welcome" alt="no image"/>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="centered">
                        <IonCol>
                            <IonButton onClick={() => setShowModal(false)}> Accept </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className="centered">
                        <IonCol>
                            <IonButton onClick={() => history.push('/home')} className="decline-btn"> Decline </IonButton>
                        </IonCol>
                    </IonRow>
                </IonModal>
            </IonGrid>
        </IonContent>
    );
};

export default WelcomeModal;
