import React from "react";
import { IonAvatar, IonBadge, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonPopover } from "@ionic/react";
import './landing.css';
import LandingTabsComponent from "../components/landing-tabs";
import { menuOutline } from "ionicons/icons";
import LandingFooterComponent from "../components/landing-footer-component";
import LandingHeaderComponent from "../components/landing-header-component";

const LandingPage: React.FC = () => {

    return (

        <IonPage>
            <IonContent id="menuContent" >
                <LandingHeaderComponent/>
                <LandingTabsComponent/>
                <IonGrid className="ion-no-padding">
                    <IonRow className="background-dark">
                        <IonCol className="max-width-900 ion-text-center">
                            <div className="landing-row ion-align-items-center ion-justify-content-center">
                                <h2>
                                    <IonText className="landing-title-text">
                                        Turn your passion for gaming into a whole different level.
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                            Enjoy online games for FREE and earn real-world rewards from playing.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonButton>
                                        <IonIcon icon="assets/icon/joystick.svg" color="white" className="ion-padding-end"></IonIcon>
                                        <IonText className="landing-bold">Play Games</IonText>
                                    </IonButton>
                                </p>
                                <p>
                                    <IonButton fill="clear" color="light">See how it works</IonButton>
                                    <IonButton fill="clear" color="light">Join the community</IonButton>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow className="landing-row background-dark-tint ion-padding-top">
                        <IonCol   size-md="7" offset-md="2.5" size-xs="11" className="ion-margin">
                            <div className="ion-text-center ion-padding-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        How It Works
                                    </IonText>
                                </h2>
                            </div>
                            <IonGrid className="ion-margin">
                                <IonRow className="ion-text-center ion-justify-content-center  ion-margin">
                                    <IonCol className="ion-align-items-center ion-padding " size-md="6" size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">1</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Sign Up
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center ">
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            </IonText>
                                        </p>
                                    </IonCol>
                                    <IonCol className="ion-align-items-center ion-padding" size-md="6"  size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">2</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2>
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Play Games
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            </IonText>
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-text-center ion-padding-vertical ion-justify-content-center   ion-margin">
                                    <IonCol className="ion-align-items-center ion-padding" size-md="6"  size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">3</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Collect In-Game Credits
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            </IonText>
                                        </p>
                                    </IonCol>
                                    <IonCol className="ion-align-items-center ion-padding" size-md="6"  size-xs="11">
                                        <IonAvatar className="landing-image-box-avatar">
                                            <IonBadge className="landing-image-box-badge" color="primary">4</IonBadge>
                                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                                        </IonAvatar>
                                        <h2 >
                                            <IonText className="landing-image-box-title ion-no-margin ">
                                                Exchange Into XAR
                                            </IonText>
                                        </h2>
                                        <p>
                                            <IonText className="landing-image-box-text ion-justify-content-center">
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            </IonText>
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-text-center ion-justify-content-center ion-padding-top ion-margin landing-center-border ">
                                    <IonCol className="ion-padding-vertical">
                                        <div className="ion-text-center ion-margin-vertical ion-padding-vertical">
                                            <h2 className="ion-no-margin">
                                                <IonText className="landing-image-box-title ion-no-margin ">
                                                    There's more to look out for!
                                                </IonText>
                                            </h2>
                                            <p>
                                                <IonText className="landing-subtitle-text ion-justify-content-center">
                                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                                                </IonText>
                                            </p>
                                            <IonButton>
                                                See All Benefits
                                            </IonButton>
                                        </div>
                                    </IonCol>


                                </IonRow>

                            </IonGrid>
                        </IonCol>
                    </IonRow>

                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5" size-xs="11" className="ion-margin">
                            <div className="ion-text-start ion-padding-vertical">
                                <p className="ion-no-margin"><IonLabel color="danger" className="landing-label">ABOUT XARCADE</IonLabel></p>
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Discover a different gaming experience.
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text  ion-justify-content-center">
                                    Xarcade enables gamers to exchange, purchase and transfer the in-game credits from the games published on the platform. It facilitates the buying of in-game credits and in-game products with XAR, enabling users to purchase “outside” of the game too.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Read more about Xarcade →</IonText>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size-md="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="1.5" size-xs="11">

                        </IonCol>
                        <IonCol size-md="5" offset-md="1" size-xs="11"className="ion-margin">
                            <div className="ion-text-start ">
                                <p className="ion-no-margin"><IonLabel color="danger" className="landing-label">OUR MISSION</IonLabel></p>
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Taking you to the next level of gaming technology
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Read more about the technology →</IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark">
                    <IonCol size-md="4" offset-md="1.5"  size-xs="11" className="ion-margin">
                        <div className="ion-text-start ion-padding">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Join the community!
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text  ion-justify-content-center">
                                        Meet new people who share the same passion as you do!
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonText className="landing-bold" color="primary">Join us in Telegram →</IonText>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size-md="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark-tint ion-padding">
                        <IonCol size-md="4" offset-md="1.5"   size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <p className="ion-no-margin"><IonLabel color="danger" className="landing-label">COMING SOON</IonLabel></p>
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Experience mobile gaming on-the-go
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonButton fill="outline" color="primary">
                                        <IonText className="landing-bold">See more details →</IonText>
                                    </IonButton>
                                </p>
                            </div>
                        </IonCol>
                        <IonCol size="4" className="">

                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark">
                        <IonCol size-md="4" offset-md="1.5"  size-xs="11" >

                        </IonCol>
                        <IonCol size-md="5" offset-md="1" size-xs="11" className="ion-margin">
                            <div className="ion-text-start ion-padding ion-margin-vertical">
                                <h2 className="ion-no-margin">
                                    <IonText className="landing-title-text ion-no-margin ">
                                        Start playing your favorite games, collect in-game credits and turn it into real-world rewards!
                                    </IonText>
                                </h2>
                                <p>
                                    <IonText className="landing-subtitle-text ion-justify-content-center">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </IonText>
                                </p>
                                <p  className="landing-play-game-button">
                                    <IonButton color="primary">
                                        <IonIcon icon="assets/icon/joystick.svg" color="light" className="ion-padding-end"></IonIcon>
                                        <IonText className="landing-bold">Play Games</IonText>
                                    </IonButton>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-dark">
                        <IonCol size-md="4" offset-md="1.5"  size-xs="11" >

                        </IonCol>
                        <IonCol size-md="3" offset-md="2" size-xs="11" className="ion-margin landing-row">
                            <iframe 
                                id="onramper-widget"
                                title="Onramper widget"
                                allow="accelerometer; autoplay; camera; gyroscope; payment;"
                                src="https://widget.onramper.com?color=266678&apiKey=pk_test_fvOyzid80LgqPWaeg7N0xrqBbrnLX4QQfmBcJdvU8GE0">
                            </iframe>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <LandingFooterComponent/>
            </IonContent>
        </IonPage>
    );
};

export default LandingPage;
