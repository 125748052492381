import React from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonRow, IonText } from "@ionic/react";
import './info.css';
import LandingHeaderComponent from "../../components/landing-header-component";
import LandingFooterComponent from "../../components/landing-footer-component";

const AboutPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent id="menuContent" >
                <LandingHeaderComponent/>
                <IonGrid className="ion-no-padding">
                    <IonRow className="landing-row background-dark ion-padding">
                        <IonCol size-md="4" offset-md="3.5" size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <h6 className="ion-no-margin">
                                    <IonText className="about-title ion-no-margin ">
                                        About Us
                                    </IonText>
                                </h6>
                                <p>
                                    <IonText className="about-text ion-justify-content-center">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </IonText>
                                </p>
                            </div>
                            <div className="large-margin-top">
                                <IonGrid>
                                    <IonRow>
                                        <IonCol sizeMd="6">
                                            <IonImg className="about-image" src="assets/blank-image.png"/>
                                            <h2 className="about-margin-top about-name">
                                                Otto von Nostitz
                                            </h2>
                                            <p className="ion-no-margin about-text">
                                                Co-founder and CEO
                                            </p>
                                        </IonCol>
                                        <IonCol sizeMd="6">
                                            <IonImg className="about-image" src="assets/blank-image.png"/>
                                            <h2 className="about-margin-top about-name">
                                                Lon Wong
                                            </h2>
                                            <p className="ion-no-margin about-text">
                                                Co-founder and Head of Strategy
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className="landing-row background-default ion-padding">
                        <IonCol size-md="4" offset-md="3.5" size-xs="11" className="ion-margin"> 
                            <div className="ion-text-start">
                                <p className="ion-no-margin"><IonLabel color="danger">Vision</IonLabel></p>
                                <h5 className="ion-no-margin">
                                    <IonText className="about-title ion-no-margin ">
                                        What we believe and stand for
                                    </IonText>
                                </h5>
                                <p className="about-quote">
                                    <IonText className="about-text ion-justify-content-center">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </IonText>
                                </p>
                                <p className="about-quote">
                                    <IonText className="about-text ion-justify-content-center">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                                        deleniti atque corrupti quos dolores et quas molestias excepturi.
                                    </IonText>
                                </p>
                            </div>
                            <div className="ion-text-end">
                                <p className="landing-play-game-button">
                                    <IonText className="landing-subtitle-text">
                                        The future is now.
                                    </IonText>
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <LandingFooterComponent/>
            </IonContent>
        </IonPage>
    );
};

export default AboutPage;
